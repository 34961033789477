import React from "react"
import "../styles/global.css"
import AppStore from "../img/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.png"
import GooglePlay from "../img/google-play-badge-01.png"

export default function DownloadApp() {
  const googleLink = `${process.env.GATSBY_GOOGLE_PLAY_STORE_LINK}`;
  const appleLink = `${process.env.GATSBY_APPLE_APP_STORE_LINK}`;


  return (
    <div className="row">
      <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2"></div>
      <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10">
        <div ><h2 className="download">Download App from</h2></div>
        <a href={appleLink}><img src={AppStore} alt=""></img></a><a href={googleLink}><img src={GooglePlay} alt=""></img></a>
        <br></br>
        <br></br>
      </div>
    </div>
  )
}