import React from "react"
import "../styles/global.css"
import DownloadApp from "../components/downloadapp"

export default function PrivacyContent() {
  return (
    <div>
      <div className="top-logo"></div>
      <br></br>
      <br></br>
      <br></br>
      <div className="privacy">
        <h2>Privacy Policy for NEO</h2>
        <h3>Privacy policy</h3>
        <p>This privacy policy ("Policy") describes how the personally identifiable information ("Personal Information") you may provide in the "NEO" mobile application ("Mobile Application" or "Service") and any of its related products and services (collectively, "Services") is collected, protected and used. It also describes the choices available to you regarding our use of your Personal Information and how you can access and update this information. This Policy is a legally binding agreement between you ("Onliner", "User", "you" or "your") and this Mobile Application developer ("Online Enterprises Inc, Online Business Systems, Operator", "we", "us" or "our"). By accessing and using the Mobile Application and Services, you consent to the use of your personal data as described in this agreement and, furthermore, you acknowledge that you have read, understood, and agree to be bound by the terms of this Agreement. This Policy does not apply to the practices of companies that we do not own or control, or to individuals that we do not employ or manage.</p>
        <h3>Automatic collection of information</h3>
        <p>When you use the Mobile Application, our servers automatically record information that your device sends. This data may include information such as your device\'s IP address and location, device name and version, operating system type and version, language preferences, information you search for in the Mobile Application, access times and dates, and other statistics.</p>
        <p>Information collected automatically is used only to identify potential cases of abuse and establish statistical information regarding the usage of the Mobile Application and Services. This statistical information is not otherwise aggregated in such a way that would identify any particular user of the system.</p>
        <h3>Collection of personal information</h3>
        <p>If you wish to use the Mobile Application, you will not be asked to provide certain Personal Information (for example, your name and e-mail address). Instead, that information will be shown in your profile and read from corporate systems that contain this information stored in secured data stores and controlled by Online Enterprises, Inc. We receive and store any information you knowingly provide to us when you publish content, or fill any online forms in the Mobile Application. When required, this information may include the following:</p>
        <ul>
          <li>Certain features on the mobile device such as contacts.</li>
          <li>Any other materials you willingly submit to us such as articles, images, and feedback.</li>
        </ul>
        <p>Some of the information we collect directly from you includes information you voluntarily input through the Mobile Application\'s features. Your personal Information is displayed to you from secure data stores that you have previously provided Online Enterprises, Inc.. Onliners who are uncertain about what information is mandatory or what information is displayed through the Mobile Application are welcome to contact us.</p>
        <h3>Use and processing of collected information</h3>
        <p>In order to make the Mobile Application and Services available to you, or to meet a legal obligation, we may need to collect and will use certain Personal Information obtained from secured corporate systems. If we request additional Personal Information from you but you do not provide the information that we request, we may not be able to provide a fully-featured experience in NEO. The information we collect from you may be used for the following purposes:</p>
        <ul>
          <li>Create and manage user accounts and profile info.</li>
          <li>Send administrative information.</li>
          <li>Respond to inquiries and offer support.</li>
          <li>Request user feedback.</li>
          <li>Improve user experience.</li>
          <li>Protect from abuse and malicious users.</li>
          <li>Run and operate the Mobile Application and Services.</li>
        </ul>
        <p>Processing your Personal Information, when applicable, depends on how you interact with the Mobile Application, where you are located in the world, and if one of the following applies: (i) you have given your consent for one or more specifc purposes; this, however, does not apply, whenever the processing of Personal Information is subject to California Consumer Privacy Act or European data protection law; (ii) provision of information is necessary for the performance of an agreement with you and/or for any pre-contractual obligations thereof; (iii) processing is necessary for compliance with a legal obligation to which you are subject; (iv) processing is related to a task that is carried out in the public interest or in the exercise of official authority vested in us; (v) processing is necessary for the purposes of the legitimate interests pursued by us or by a third party.</p>
        <p>Note that under some legislations we may be allowed to process information until you object to such processing (by opting out), without having to rely on consent or any other of the following legal bases below. In any case, we will be happy to clarify the specific legal basis that applies to the processing, and in particular whether the provision of Personal Information is a statutory or contractual requirement, or a requirement necessary to enter into a contract.</p>
        <p><strong>All Processing of Personal Information will occur on secured corporate systems referencing secured data stores. Opting out of accepting this would require you to contact us.</strong></p>
        <h3>Disclosure of information</h3>
        <p>We do not share Personal Information with unaffiliated third parties nor do we have any trusted third parties that exist today that assist us in supporting the Mobile Application.</p>
        <p>We will disclose any Personal Information we may collect, use or receive if required or permitted by law, such as to comply with a subpoena, or similar legal process, and when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or respond to a legal government request.</p>
        <h3>Retention of information</h3>
        <p>We will retain and use your Personal Information for the period necessary to comply with our legal obligations, resolve disputes, enforce our agreements, and support regular corporate operations, unless a longer retention period is required or permitted by law. You are reminded here that all Personal Information displayed in the application is sourced from secured corporate data stores, other than any Personal information you voluntarily submit. We may use any aggregated data derived from or incorporating your Personal Information after you update or delete it, but not in a manner that would identify you personally. Once the retention period expires, Personal Information will be deleted. Therefore, the right to access, the right to erasure, the right to rectification and the right to data portability cannot be enforced after the expiration of the retention period.</p>
        <h3>The rights of users</h3>
        <p>You may exercise certain rights regarding your information processed by us. In particular, you have the right to do the following: (i) you have the right to withdraw consent where you have previously given your consent to the processing of your information (ii) you have the right to object to the processing of your information if the processing is carried out on a legal basis other than consent; (iii) you have the right to learn if information is being processed by us, obtain disclosure regarding certain aspects of the processing and obtain a copy of the information undergoing processing; (iv) you have the right to verify the accuracy of your information and ask for it to be updated or corrected; (v) you have the right, under certain circumstances, to restrict the processing of your information, in which case, we will not process your information for any purpose other than storing it; (vi) you have the right, under certain circumstances, to obtain the erasure of your Personal Information from us; (vii) you have the right to receive your information in a structured, commonly used and machine readable format and, if technically feasible, to have it transmitted to another controller without any hindrance. This provision is applicable provided that your information is processed by automated means and that the processing is based on your consent, on a contract which you are part of or on pre-contractual obligations thereof. All request to exercise these rights must be done through a formal process you initiate Online Enterprises, Inc. and may affect your existing employment contract, if there are conflicts to consent already given.</p>
        <h3>California privacy rights</h3>
        <p>In addition to the rights as explained in this Policy, California residents who provide Personal Information (asdefined in the statute) to obtain products or services for personal, family, or household use are entitled to request and obtain from us, once a calendar year, information about the Personal Information we shared, if any, with other businesses for marketing uses. If applicable, this information would include the categories of Personal Information and the names and addresses of those businesses with which we shared such personal information for the immediately prior calendar year (e.g., requests made in the current year will receive information about the prior year). To obtain this information please contact us.</p>
        <p><strong>The Mobile Application is not used in any way for you to obtain products as described above but the statement is here for your comfort and for completeness.</strong></p>
        <h3>How to exercise these rights</h3>
        <p>Any request to exercise your rights can be directed to the Operator through the contact details provided in this document. Please note that we may ask you to verify your identity before responding to such requests. Your request must provide sufficient information that allows us to verify that you are the person you are claiming to be or that you are the authorized representative of such person. You must include sufficient details to allow us to properly understand the request and respond to it. We cannot respond to your request or provide you with Personal Information unless we first verify your identity or authority to make such a request and confirm that the Personal Information relates to you.</p>
        <h3>Privacy of children</h3>
        <p>We do not knowingly collect any Personal Information from children under the age of 18 and if any of this information is collected, it would be voluntarily given by an Onliner, which is an employee of Online Enterprises, Inc.. If you are under the age of 18 and employed by Online Enterprises, Inc., please do not submit any Personal Information through the Mobile Application and Services. We encourage parents and legal guardians to monitor their children\'s Internet usage and to help enforce this Policy by instructing their children never to provide Personal Information through the Mobile Application and Services without their permission. If you have reason to believe that a child under the age of 18 has provided Personal Information to us through the Mobile Application and Services, please contact us. You must also be old enough to consent to the processing of your Personal Information in your country (in some countries we may allow your parent or guardian to do so on your behalf).</p>
        <h3>Links to other resources</h3>
        <p>The Mobile Application contains links to other resources that are not owned or controlled by us, most notably SaaS messaging applications, but this may expand in the future. Please be aware that we are not responsible for the privacy practices of such other resources or third parties. We encourage you to be aware when you leave the Mobile Application and to read the privacy statements of each and every resource that may collect Personal Information.</p>
        <h3>Information security</h3>
        <p>We secure information you provide on computer servers in a controlled, secure environment, protected from unauthorized access, use, or disclosure. We maintain reasonable administrative, technical, and physical safeguards in an effort to protect against unauthorized access, use, modification, and disclosure of Personal Information in its control and custody. However, no data transmission over the Internet or wireless network can be guaranteed. Therefore, while we strive to protect your Personal Information, you acknowledge that (i) there are security and privacy limitations of the Internet which are beyond our control; (ii) the security, integrity, and privacy of any and all information and data exchanged between you and the Mobile Application and Services cannot be guaranteed; and (iii) any such information and data may be viewed or tampered with in transit by a third party, despite best efforts.</p>
        <h3>Data breach</h3>
        <p>In the event we become aware that the security of the Mobile Application and Services has been compromised or users Personal Information has been disclosed to unrelated third parties as a result of external activity, including, but not limited to, security attacks or fraud, we reserve the right to take reasonably appropriate measures, including, but not limited to, investigation and reporting, as well as notification to and cooperation with law enforcement authorities. In the event of a data breach, we will make reasonable efforts to notify affected individuals if we believe that there is a reasonable risk of harm to the user as a result of the breach or if notice is otherwise required by law. When we do, we will send you an email to your corporate mail identity or contact you by any other means available to us.</p>
        <h3>Changes and amendments</h3>
        <p>We reserve the right to modify this Policy or its terms relating to the Mobile Application from time to time, in our discretion, and will notify you of any material changes to the way in which we treat Personal Information. When we do, we will send you an email to notify you using your corporate email identity. We may also provide notice to you in other ways in our discretion, such as through contact information you have provided. Any updated version of this Policy will be effective immediately upon the posting of the revised Policy unless otherwise speci􀂦ed. Your continued use of the Mobile Application after the effective date of the revised Policy (or such other act speci􀂦ed at that time) will constitute your consent to those changes. However, we will not, without your consent, use your Personal Information in a manner materially different than what was stated at the time your Personal Information was collected.</p>
        <h3>Acceptance of this policy</h3>
        <p>You acknowledge that you have read this Policy and agree to all its terms and conditions. By accessing and using the Mobile Application you agree to be bound by this Policy. If you do not agree to abide by the terms of this Policy, you are not authorized to access or use the Mobile Application and do so at your own risk.</p>
        <h3>Contacting us</h3>
        <p>If&nbsp;you would like to contact us to understand more about this Policy or wish to contact us concerning any matter relating to individual rights and your Personal Information, you may send an email to <strong><a href="mailto:helpdesk@obsglobal.com" target="_blank">helpdesk@obsglobal.com</a></strong></p>
        <p>This document was last updated on January 21, 2021</p>
      </div>
    </div>
  )
}