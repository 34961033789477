import React from "react"
import "../styles/global.css"

export default function Footer() {
  return (
    <div className="row">
        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2"></div>
        <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 bottom-logo">
            <br></br>
            {/* <br></br>  */}
            <div className="display-hidden">
                <br></br> 
                <br></br> 
                <br></br> 
                <br></br> 
                <br></br>
            </div>   
            <span className="copyright">Copyright © {new Date().getFullYear()} Online Business Systems</span>
            <br></br>
            <br></br>
        </div>
    </div>
    )
}