import React from "react"
import "../styles/global.css"
//import Header from "../components/header"
import Footer from "../components/Footer"
import PrivacyContent from "../components/privacycontent"

export default function Home() {
  return ( 
    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 home-screen">
      <div className="top-screen">  
        <PrivacyContent />
      </div>
      <div className="bottom-screen">
        <Footer />
      </div>
    </div>
  )
}
